export const loadState = (name, isJson) => {
  try {
    let serializedState = null;

    if (typeof window !== `undefined`) {
      // code that references a browser global
      serializedState = window.localStorage.getItem(name);
    }

    if (serializedState === null) {
      return undefined;
    }

    if (isJson) {
      return JSON.parse(serializedState);
    }
    return serializedState;
  } catch (e) {
    return undefined;
  }
};

export const saveState = (name, value) => {
  try {
    let serializedValue = value;
    if (typeof value === "object") {
      serializedValue = JSON.stringify(serializedValue);
    }
    if (typeof window !== `undefined`) {
      // code that references a browser global
      window.localStorage.setItem(name, serializedValue);
    }
  } catch (e) { }
};

export const clearAllState = () => {

  try {

    let serializedState = null;
    serializedState = window.localStorage.clear();

  } catch (error) {
    console.log(error)
  }
}
